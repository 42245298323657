import "styles/pages/page-blog.scss";

import React from "react";
import { Link, graphql } from "gatsby";
import { useQueryParam, StringParam } from "use-query-params";

import Layout from "components/Layout";
import Seo from "components/Seo";
import SubpageHeader from "components/SubpageHeader";

const Blog = ({ data }) => {
  const isBrowser = typeof window !== "undefined";

  const posts = data.allPosts.edges;
  const news = data.allNews.edges;
  const allData = [...posts, ...news];

  let _data = allData;

  const [search] = useQueryParam("search", StringParam);
  if (!!search) {
    _data = _data.filter((product) =>
      new RegExp(search, "i").test(product.node.title)
    );
  }

  return (
    <Layout headerColor="#0260DB">
      <Seo title="Wyszukiwanie" />
      <SubpageHeader
        image={require("assets/images/blog-top-bg.jpg").default}
        borderColor="#0260DB"
      />

      <section className="section">
        <div className="container">
          <h2 className="section-title">Wyszukiwanie: {isBrowser && search}</h2>
        </div>
      </section>

      <section className="blog">
        <div className="container">
          <div className="row">
            {_data.length && isBrowser && search.length > 0 ? (
              _data.map((item, index) => (
                <div className="col-md-4" key={index}>
                  <div className="blog-tile">
                    <div
                      className="blog-tile__thumb"
                      style={{
                        backgroundImage: `url(${item.node.featuredImage.node.sourceUrl})`,
                      }}
                    ></div>

                    <div className="blog-tile__info blog-tile__info--news">
                      <data>
                        {item.node.date
                          .split("T")[0]
                          .split("-")
                          .reverse()
                          .join(".")}
                      </data>
                    </div>
                    <div className="blog-tile__inner">
                      <h4>{item.node.title}</h4>
                      <br />
                      {item.node.id === "cG9zdDoxNDk=" ? (
                        <Link
                          to={`/blog/${item.node.slug}/`}
                          className="button button--yellow"
                        >
                          Czytaj więcej
                        </Link>
                      ) : (
                        <Link
                          to={`/wiadomosci-podroznicze/${item.node.slug}/`}
                          className="button button--yellow"
                        >
                          Czytaj więcej
                        </Link>
                      )}
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <p>Brak wyników.</p>
            )}
          </div>
        </div>
      </section>
    </Layout>
  );
};

export const query = graphql`
  query {
    allPosts: allWpPost(sort: { fields: date, order: DESC }) {
      edges {
        node {
          slug
          title
          date
          featuredImage {
            node {
              sourceUrl
            }
          }
          id
        }
      }
    }
    allNews: allWpNew(sort: { fields: date, order: DESC }) {
      edges {
        node {
          slug
          title
          date
          featuredImage {
            node {
              sourceUrl
            }
          }
        }
      }
    }
  }
`;

export default Blog;
